import React, { useState } from "react";
import SoundItem from "./SoundItem";
import {
  faTree,
  faCloudBolt,
  faFire,
  faWind,
  faWater,
  faMoon,
  faMugHot,
  faMusic,
  faGuitar,
} from "@fortawesome/free-solid-svg-icons";
import { Logo } from "./Logo";

interface Sound {
  src: string;
  icon: any;
  playing: boolean;
  volume: number;
}

const soundList: Sound[] = [
  { src: "/sounds/forest.wav", icon: faTree, playing: false, volume: 0.5 },
  {
    src: "/sounds/thunder.wav",
    icon: faCloudBolt,
    playing: false,
    volume: 0.5,
  },
  { src: "/sounds/fireplace.mp3", icon: faFire, playing: false, volume: 0.5 },
  { src: "/sounds/wind.wav", icon: faWind, playing: false, volume: 0.5 },
  { src: "/sounds/waves.mp3", icon: faWater, playing: false, volume: 0.5 },
  { src: "/sounds/night.mp3", icon: faMoon, playing: false, volume: 0.5 },
  { src: "/sounds/people.mp3", icon: faMugHot, playing: false, volume: 0.5 },
  {
    src: "/sounds/piano.mp3",
    icon: faMusic,
    playing: false,
    volume: 0.5,
  },
  {
    src: "/sounds/guitar.mp3",
    icon: faGuitar,
    playing: false,
    volume: 0.5,
  },
];

const SoundLibrary: React.FC = () => {
  const [sounds, setSounds] = useState(soundList);
  const randomizeSounds = () => {
    const newSoundStates = soundList.map((sound) => ({
      ...sound,
      playing: Math.random() >= 0.5,
      volume: Math.random(),
    }));
    setSounds(newSoundStates);
  };
  return (
    <div className="w-[60vw] mx-[20vw]">
      <div className="flex w-full justify-center">
        <Logo
          onClick={() => {
            randomizeSounds();
          }}
          className="hover:rotate-90 transition-rotate duration-500 shadow-md rounded-full mb-20 w-20 h-20"
        />
      </div>
      <div className="sound-library grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {sounds.map((sound, index) => (
          <SoundItem key={index} src={sound.src} icon={sound.icon} />
        ))}
      </div>
    </div>
  );
};

export default SoundLibrary;
