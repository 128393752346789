import React from "react";
import SoundLibrary from "./components/SoundLibrary";
import { Logo } from "./components/Logo";
import { Analytics } from "@vercel/analytics/react";
import "./App.css";

function App() {
  const [randomize, setRandomize] = React.useState(0);

  const triggerRandomize = () => {
    setRandomize(Math.random());
  };

  return (
    <div
      className="App min-h-screen py-10"
      style={{
        background: "linear-gradient(180deg, #813F73 0%, #000000 100%)",
      }}
    >
      <Analytics></Analytics>
      <SoundLibrary />
    </div>
  );
}

export default App;
