import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SoundItemProps {
  src: string;
  icon: any;
}

const SoundItem: React.FC<SoundItemProps> = ({ src, icon }) => {
  const [audio] = useState(new Audio(src));
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);

  const toggleSound = () => {
    if (playing) {
      audio.pause();
    } else {
      audio.loop = true;
      audio.play();
    }
    setPlaying(!playing);
  };

  const handleChange = (e: any) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audio.volume = newVolume;
  };

  return (
    <div className="sound-item h-24 flex flex-col items-center mb-4">
      <button
        onClick={toggleSound}
        className={`focus:outline-none ${
          playing ? "text-gray-100 opacity-100" : "text-gray-100 opacity-20"
        }`}
      >
        <FontAwesomeIcon icon={playing ? icon : icon} size="3x" />
      </button>
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={(e) => handleChange(e)}
        // onBlur={(e) => handleVolumeChange(e)}
        className={`${
          playing ? "" : "hidden"
        } mt-8 w-20 bg-gray-300 appearance-none h-0.5 rounded focus:outline-none`}
        style={{
          background: `linear-gradient(90deg, #ffffff ${
            volume * 100
          }%, #ffffff44 ${volume * 100}%)`,
        }}
      />
    </div>
  );
};

export default SoundItem;
